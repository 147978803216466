.hero-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem; /* Adjust padding */
    /* background-color: #fff; */
  }
  
  .hero-content {
    flex: 1 1 50%; /* Allows resizing */
    max-width: 50%;
    margin-right: 2rem; /* Spacing between content and image */
    text-align: left;

  }
  
  .hero-content h1 {
    font-size: 2.5rem; /* Adjusted for responsiveness */
    line-height: 1.2;
    margin-bottom: 1rem;
    /* color: #333; */
  }
  
  .hero-content .highlight {
    color: #a954ff;
    font-weight: bold;
  }
  
  .hero-content p {
    font-size: 1rem; /* Adjusted for readability */
    line-height: 1.6;
    /* color: #555; */
    margin-bottom: 2rem;
  }
  
  .hero-button {
    background-color: #0066ff;
    /* color: #fff; */
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none; /* Prevents underline */
    display: inline-block;
  }
  
  .hero-button:hover {
    background-color: #0052cc;
  }
  
  .hero-image {
    flex: 1 1 40%; /* Allows resizing */
    max-width: 40%;
  }
  
  .hero-image img {
    width: 100%; /* Full width for responsiveness */
    height: auto; /* Maintain aspect ratio */
  }
  
  @media (max-width: 768px) {
    .hero-section {
        margin-top: 50px;
      flex-direction: column; /* Stack elements vertically */
      padding: 2rem 1rem; /* Adjust padding */
    }
  
    .hero-content {
      max-width: 100%; /* Full width on smaller screens */
      margin-bottom: 2rem; /* Space below content */
    }
  
    .hero-content h1 {
      font-size: 2rem; /* Adjusted font size */
    }
  
    .hero-content p {
      font-size: 0.9rem; /* Smaller text for readability */
    }
  
    .hero-image {
      max-width: 100%; /* Full width */
    }
  
    .hero-image img {
      width: 100%; /* Full width */
    }
  }
  
  @media (max-width: 480px) {
    .hero-section {
      padding: 1rem; /* Reduce padding */
    }
  
    .hero-content h1 {
      font-size: 1.5rem; /* Further reduced font size */
    }
  
    .hero-content p {
      font-size: 0.8rem; /* Smaller text for mobile */
    }
  
    .hero-button {
      padding: 0.5rem 1rem; /* Adjust button size */
    }
  }
  