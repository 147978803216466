@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.career-page {
    margin-top: 50px;
    padding: 20px;
    /* background-color: #f9f9f9; */
    font-family: 'Poppins', sans-serif; /* Updated font */
  }
  
  h1, h2 {
    /* color: #333; */
    font-family: 'Poppins', sans-serif; /* Ensuring headers use the same font */
  }
  
  p {
    /* color: #555; */
    line-height: 1.6;
    font-family: 'Poppins', sans-serif; /* Ensuring paragraph text uses the same font */
  }
  
  .job-listings {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .job-posting {
    /* background-color: #fff; */
    border: 1px solid #ddd;
    border-radius: 8px;
    /* padding: 15px; */
    min-width: calc(25% - 20px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .job-posting h2 {
    margin: 0 0 10px 0;
  }
  
  .apply-button {
    background-color: #007bff;
    /* color: #fff; */
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .apply-button:hover {
    background-color: #0056b3;
  }
  
  .freelance-trainers {
    margin-top: 40px;
    /* background-color: #e6f7ff; */
    padding: 20px;
    border-radius: 8px;
  }
  
  .freelance-trainers ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .freelance-trainers button {
    margin-top: 10px;
  }
  