/* src/pages/Contact.css */
.contact {
    padding: 20px; /* Add some padding */
    text-align: center; /* Center-align the text */
  }
  
  .contact h1 {
    font-size: 2.5rem; /* Larger font size for the main heading */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  .contact p {
    font-size: 1.2rem; /* Slightly larger font size for paragraphs */
    line-height: 1.6; /* Improve line spacing */
    margin-bottom: 15px; /* Space below each paragraph */
  }
  
  .contact a {
    color: #007bff; /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  .contact a:hover {
    text-decoration: underline; /* Underline on hover */
  }
  