.OnlineClass {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;

  padding: 20px;
  /* background: #f8f9fa; */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.OnlineClass h1 {
  text-align: center;
  /* color: #343a40; */
  margin-bottom: 20px;
}

.OnlineClass p {
  font-size: 16px;
  line-height: 1.6;
  /* color: #495057; */
  margin-bottom: 20px;
}

.OnlineClass h2 {
  /* color: #495057; */
  margin-bottom: 10px;
}

.OnlineClass ul {
  padding-left: 20px;
}

.OnlineClass ul li {
  margin-bottom: 10px;
  list-style-type: disc;
  /* color: #212529; */
}
