/* src/pages/About.css */
.about {
    padding: 20px; /* Add some padding */
    text-align: center; /* Center-align the text */
  }
  
  .about h1 {
    font-size: 2.5rem; /* Larger font size for the main heading */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  .about p {
    text-align: left;
    font-size: 1.2rem; /* Slightly larger font size for paragraphs */
    line-height: 1.6; /* Improve line spacing */
    margin-bottom: 15px; /* Space below each paragraph */
  }
  