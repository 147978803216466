/* Footer CSS */
footer {
    position: relative;
    padding-bottom: 50px; /* Make space for the image */
  }
  
  .footer-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px; /* Adjust height as needed */
    background-image: url('../../public/assets/images/footer-bg.svg'); /* Correct path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.1;
  }
  
  .container {
    position: relative;
  }
  .footer-links {
    margin-bottom: 10px;
  }
  
  .footer-link {
    /* color: #000; */
    margin-right: 15px;
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: underline;
    color: #007bff;
  }
  