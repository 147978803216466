/* Custom Styling for Cart Page */
.logins
{
    padding: 20px;
    margin-top: 200px;
    margin-bottom: 100px;
}
.logins h2
{
    text-align: center;

}
.login-items {
    margin-top: 20px;
  }
  
  
  .logins .card .btn
  {
    width: 100%;
  }
/* Login Form Container */
form {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    /* background-color: #ffffff; */
    border-radius: 10px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  /* Heading */
  form h2 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    /* color: #333333; */
  }
  
  /* Input Fields */
  form input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  /* Focus Effect */
  form input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  /* Submit Button */
  form button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    /* color: #ffffff; */
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Hover Effect */
  form button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    form {
      padding: 15px;
    }
    form h2 {
      font-size: 1.5rem;
    }
    form input {
      font-size: 0.9rem;
    }
    form button {
      font-size: 0.9rem;
    }
  }
  
  .passIcon
  {
    position: absolute;
    right: 2px;
    background: transparent;
    top: 15px;
    opacity: 0.50;
    z-index: 999;
  }