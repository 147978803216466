/* Search and Sort Container */
.search-sort-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  }
  
  /* Search Input */
  .search-input {
    width: 60%;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  /* Sort Dropdown */
  .sort-dropdown {
    width: 30%;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  /* Clear Button Style */
  .clear-button {
    background: none;
    border: none;
    /* color: #888; */
    font-size: 18px;
    cursor: pointer;
    margin-left: 8px;
  }
  
  /* Style for the Cross Icon */
  .clear-icon {
    font-size: 20px;
  }
  
  /* Clear button on hover */
  .clear-button:hover {
    color: #f00;
  }
  
  /* Courses Container */
  .courses {
    padding: 20px;
    text-align: center;
  }
  
  /* Responsive Layout for Small Screens */
  @media (max-width: 768px) {
    .search-input,
    .sort-dropdown {
      width: 100%; /* Full width for input and dropdown */
    }
  
    .search-sort-container {
      flex-direction: column; /* Stack input and dropdown on top of each other */
      align-items: stretch; /* Make them full width */
    }
  
    .clear-button {
      align-self: flex-start; /* Align clear button to the left */
      margin-top: 5px;
    }
  }
  
  /* Responsive Layout for Extra Small Screens */
  @media (max-width: 480px) {
    .search-input,
    .sort-dropdown {
      width: 100%;
      font-size: 14px; /* Smaller font size for smaller screens */
    }
  
    .clear-button {
      font-size: 16px; /* Smaller cross icon */
    }
  }
  
  /* Display Courses */
  .courses-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .col-md-4,
  .col-sm-6 {
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
    flex-basis: calc(33.333% - 20px) !important;
    margin: 10px;
  }
  
  @media (max-width: 768px) {
    .col-md-4 {
      flex-basis: calc(50% - 20px) !important ; /* 2 items per row on medium screens */
    }
  }
  
  @media (max-width: 480px) {
    .col-md-4 {
      flex-basis: calc(100% - 20px); /* 1 item per row on small screens */
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .page-button {
    background-color: #007bff;
    /* color: #fff; */
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .page-button:hover {
    background-color: #0056b3;
  }
  
  .page-button.active {
    background-color: #0056b3;
    font-weight: bold;
  }
  
  .page-button:disabled {
    /* background-color: #d6d6d6; */
    cursor: not-allowed;
  }
  
  .items-per-page-container {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }
  
  .items-per-page-label {
    margin-right: 10px;
    font-size: 14px;
  }
  
  .items-per-page-select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  