.course-details-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-top: 100px;
  }
  
  .tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Allows the tabs to wrap onto multiple lines */
  overflow-x: auto; /* Adds horizontal scrolling if needed */
  }
  
  .tab {
    flex: 1 1 auto; /* Allows the tab to grow and shrink */
    padding: 10px 20px;
    background-color: #e0e0e0;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center; /* Centers the text */
    min-width: 100px; /* Minimum width for each tab */
  }
  
  .tab.active {
    background-color: #2196f3;
    color: white;
  }
  
  .tab-content {
    padding: 2px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .details-tab {
    display: flex;
    gap: 20px;
  }
  
  .details-image-container {
    position: relative;
  }
  
  .details-image {
    width: 400px;
    height: 200px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .video-popup {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 8px;
  }
  
  .topics-tab ul {
    list-style: none;
    padding: 0;
  }
  
  .topic-item {
    display: flex;
    align-items: start;
    gap: 5px;
    margin-bottom: 5px;
    justify-content: flex-start;

  }
  
  .topic-image {
    width: 200px;
    height: 100px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  /* .video-container {
    margin-top: 20px;
  } */
  
  .video-container iframe {
    width: 100%;
    height: 315px;
    border-radius: 8px;
  }
.topicLi p
{
    cursor: pointer;
    font-size: .85rem;
    line-height: 1.5rem;
}  
.topicLi i
{
  color:black;
}
.topicLi
{
  list-style-type: none;
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Center the buttons */
  gap: 16px; /* Add space between buttons */
  margin-top: 30px; /* Add space above the button container */
}
.card-body-radius
{
  border-radius: 4px;
}


.perk-card {
  max-height: 100px;
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures content doesn't overflow the card */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px; /* Padding inside the card */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
}

/* Header styling */
.perk-card-header {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the icon and title */
  text-align: center; /* Center text */
  flex-direction: column;

}

.perk-card i {
  font-size: 2rem; /* Icon size */
  color: #1f2937; /* Icon color */
  text-align: start;
}

.perk-card-title {
  font-size: .75rem;
  font-weight: bold;
  margin: 0; /* Remove default margin */
  text-align: center;
}


.detail-h1
{
  margin: 20px;
}

.objective
{
  padding: 1rem;
}
@media (max-width: 768px) {

.perks
{
  width: 94%;
}
}
.details-content
{
  height: 100%;
}
.bullet-points
{
  list-style-type: none;
}
.bullet-points-feature
{
  width: 400px;
  min-height: 100px;
  display: inline-flex;
}
.bullet-points-feature p
{
  line-height: 1.5rem;
}
.icon-container i
{
  color: #43c466;
  transition: color 0.3s ease;
  /* width: 40px; Adjust size as needed */
  /* height: 40px; Adjust size as needed */
  font-size: 1.5rem; /* Slightly smaller icon */
  margin-right: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}
@media (max-width: 768px) {
  .bullet-points-feature
{
  width: auto;
}
}