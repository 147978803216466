

.modal {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 95%;
    max-width: 500px;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 5px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    font-family: Arial, sans-serif;
    font-size: 16px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    height: 415px;
}

.closeButton {
    align-self: flex-end;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #888;
}

/* Add your existing styles here */


.formGroup {
    margin-bottom: 2px;
}

.label {
    display: inline-block;
    width: 120px; /* Adjust the width as needed */
    margin-right: 10px; /* Space between the label and the input */
    font-weight: bold;
    vertical-align: middle;
    font-size: 0.8rem;
}
.input
{
    height: 30px;

}
.input,
.select,
.textarea {
    display: inline-block;
    width: calc(100% - 130px); /* Adjust the width calculation as needed */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    vertical-align: middle;
}

.input:focus,
.select:focus,
.textarea:focus {
    border-color: #007bff;
    outline: none;
}

.button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #0056b3;
}
button
{
    margin-top: unset;
}
[type=submit]
{
    width: 80px;
    margin: 0 auto;
    padding: 3px;
    margin-top: 10px;
    margin-left: 167px;
}
.form {
    /* Styles for the form */
    padding: 10px;
    margin: 20px;
    max-width: unset;
    text-align: left;
}