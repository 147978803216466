.custom-roadmap-container {
    text-align: center;
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .layout-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  .layout-icon {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .layout-icon.active {
    background-color: #3498db;
    color: white;
  }
  
  .layout-icon:hover {
    background-color: #d9eaf7;
  }
  
  /* Roadmap container */
  .roadmap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 20px;
  }
  
  .roadmap.vertical {
    flex-direction: column;
  }
  
  .roadmap.horizontal {
    flex-direction: row;
  }
  
  .roadmap-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 150px;
  }
  
  .roadmap-step.vertical {
    width: auto;
  }
  
  .step-content {
    text-align: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .step-number {
    background-color: #3498db;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -30px auto 10px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .step-title {
    margin: 0;
    font-size: 1.1rem;
    font-weight: bold;
  }
  
  .step-description {
    font-size: 0.9rem;
    /* color: #666; */
  }
  
  .step-connector {
    width: 80px;
    height: 2px;
    background-color: #3498db;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }
  
  .step-connector.vertical {
    width: 2px;
    height: 80px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  /* Hide horizontal button on mobile view */
.hide-on-mobile {
    display: block;
  }
  
  @media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
  }