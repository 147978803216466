.profiles
{
    padding: 20px;
    margin-top: 100px;
    margin-bottom: 100px;


/* Profile page styling */
.card {
    border-radius: 10px;
  }
  
  .card-body {
    padding: 30px;
  }
  
  .card-title {
    font-size: 22px;
    font-weight: bold;
  }
  
  .mb-3 p {
    margin-bottom: 0;
  }
  
  button {
    font-size: 16px;
    margin-top: 20px;
  }
}