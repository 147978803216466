/* General Styles for the Category Section */
.categories {
    text-align: center;
    padding: 40px 20px;
    /* margin-top: 50px; */
    /* background-color: #f4f7fa; Subtle background for the section */
    border-radius: 8px;
}

.categories h2 {
    font-size: 1.8rem; /* Smaller font size */
    margin-bottom: 20px;
    /* color: #333; */
    font-weight: 600;
}

.category-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 6 cards per row on large screens */
    gap: 15px; /* Reduced gap between cards */
    justify-items: center;
}

/* Styling Each Category Card */
.category-card {
    /* background-color: #ffffff; */
    padding: 20px; /* Reduced padding */
    border-radius: 10px; /* Slightly smaller border radius */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 250px; /* Reduced max-width */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
}

.category-card:hover {
    transform: translateY(-8px); /* Slight lift effect */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    /* background-color: #f8f8f8; */
}

.category-card.selected {
    background-color: #007bff;
    color: white;
}

.category-card h4 {
    margin-bottom: 10px; /* Reduced margin */
    /* color: #333; */
    font-size: 1rem; /* Smaller font size */
    font-weight: 500;
}

.category-card p {
    font-size: 0.9rem; /* Slightly smaller font size */
    /* color: #666; */
    line-height: 1.4;
    margin-bottom: 10px; /* Reduced margin */
}

/* Styling for the Category Icon */

.category-icon {
    /* color: #007bff; */
    transition: color 0.3s ease;
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Ensures the border is round */
    border: 0.1px solid #3767eb; /* Border color */
    font-size: 2.5rem; /* Slightly smaller icon */
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;


  }
/* Change Icon Color when Card is Selected */
.category-card.selected .category-icon {
    color: white;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .category-list {
        grid-template-columns: repeat(3, 1fr); /* 3 cards per row on medium screens (tablets) */
    }
}

@media (max-width: 768px) {
    .category-list {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row on smaller tablets */
    }

    .categories {
        padding: 30px 15px;
    }
}

@media (max-width: 480px) {
    .categories h2 {
        font-size: 1.5rem; /* Even smaller font size for mobile */
    }

    .category-card {
        padding: 15px; /* Even smaller padding */
    }

    .category-card h4 {
        font-size: 1rem; /* Smaller title font size */
    }

    .category-card p {
        font-size: 0.8rem; /* Even smaller text for mobile */
    }

    .category-list {
        grid-template-columns: 1fr; /* 1 card per row on small screens */
    }
}
/* Description Styling: Limit to 2 lines and ellipsis */
.category-card p {
    font-size: 0.9rem; /* Slightly smaller font size */
    color: #666;
    line-height: 1.4;
    margin-bottom: 10px; /* Reduced margin */
    display: -webkit-box; /* Enables the multi-line truncation */
    -webkit-line-clamp: 2; /* Limits the description to 2 lines */
    -webkit-box-orient: vertical; /* Defines the box as vertical */
    overflow: hidden; /* Ensures text is clipped */
    text-overflow: ellipsis; /* Adds the ellipsis */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
}
/* Override Slick Carousel styles */
.category-slider {
    margin-top: 20px;
  }
  


  
  .slick-dots li button:before {
    color: #007bff; /* Custom dot color */
  }
  
  .slick-prev:before,
  .slick-next:before {
    font-size: 20px; /* Larger navigation arrows */
  }
  
  .slick-slide {
    display: flex !important; /* Ensure cards are centered */
    justify-content: center;
  }
  
  .slick-slide > div {
    display: flex; /* Ensure proper card alignment */
    justify-content: center;
  }
  /* Carousel Dots Spacing */
.slick-dots {
    margin-top: 20px !important; /* Adds space between the cards and dots */
}

/* Navigation Button Visibility and Placement */
.slick-prev,
.slick-next {
    color: #007bff;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    top:30% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease, transform 0.3s ease;
}

.slick-prev:hover,
.slick-next:hover {
    background: #007bff;
    color: white;
    transform: scale(1.1);
}
.slick-prev:before, .slick-next:before
{
    color:#0d6efd !important;

}
.slick-prev {
    left: -50px;
}

.slick-next {
    right: -50px;
}

/* Card Alignment in the Carousel */
.slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}
.slick-slider
{
    max-height: 200px;
}

  