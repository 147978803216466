.c-card
{

.mpt-course-card {
  width: 320px;
  font-weight: 700;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

p
{
  color: rgb(192 196 206);
  font-size: .875rem;
  line-height: 1.25rem;
  text-align: start;
}

.card-image-container {
  position: relative;
}

.course-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.premium-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: gold;
  color: #000;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.9rem;
}

.card-body {
  padding: 16px;
  text-align: center;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 8px 0;
  text-align: start;
  text-decoration: none;
}

.card-text {
  font-size: 0.8rem;
  margin-bottom: 12px;
  min-height: 80px;
}


.reviews {
  color: #ddd;
}

.button
{
  font-size: 1rem;
  font-weight: bold;
  padding: 0px;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 2rem;
  font-weight: 500;
  font-size: .875rem;
    line-height: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-decoration: none;
    display: inline-flex;
    justify-content: space-between;
    gap:8px;
    align-items: center;
        height: 1.80rem;
        font-size: 0.8rem;
        
}
.view-button {
  background-color: #007bff;
  color: #000000;
}

.view-button:hover {
  background-color: #e64a19;
}
.enroll-button {
  background-color: #ff5722;
  color: #fff;
}

.enroll-button:hover {
  background-color: #e64a19;
}


.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  line-height: 1.2em;
  max-height: 2.4em;
}

.truncate1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to 1 line */
  -webkit-box-orient: vertical;
  line-height: 1.2em;
  max-height: 1.4em;
}
/* Add this to CourseCard.css */
.button-container {
  display: flex;
  justify-content: flex-end; /* Center the buttons */
  gap: 16px; /* Add space between buttons */
  margin-top: 30px; /* Add space above the button container */
}

}