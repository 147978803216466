/* Base styles for body */
body.light {
  background-color: #ffffff;
  color: #000000;
}

body.dark {
  background-color: #121212;
  color: #ffffff;
}

/* Global styling for all elements */
body.light * {
  background-color: inherit;
  color: inherit;
  border-color: inherit; /* For elements with borders */
}

body.dark * {
  background-color: inherit;
  color: inherit;
  border-color: inherit;
}

/* You can add more specific styles if needed */
button.light, button.dark {
  border: 1px solid;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

button.light {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

button.dark {
  background-color: #444;
  color: #ddd;
  border-color: #444;
}

/* Link styles */
a.light {
  color: #007bff;
}

a.dark {
  color: #1e90ff;
}

/* Add hover effects */
button.light:hover {
  background-color: #45a049;
}

button.dark:hover {
  background-color: #555;
}

a.light:hover {
  text-decoration: underline;
}

a.dark:hover {
  text-decoration: underline;
}
/* Styling specifically for div elements */
body.light div {
  background-color: #ffffff;
  color: #000000;
}

body.dark div {
  background-color: #1e1e1e;
  color: #ffffff;
  border-color: #444; /* Optional: style borders */
}

body.dark .navbar-toggler {
  background-color: #888;
  color: #000000;
  border-color: #444; /* Optional: style borders */

}


body.light .card-body , body.light .card-body .course-details,body.light .card-body .rating,body.light .card-body .button-container
{
  
  background-color: rgb(41 44 52) !important;
  color: rgb(226 228 233);
} 
body.light .category-icon
{
  border: 0.1px solid #3767eb; /* Border color */
  color: #007bff;

}
body.dark .category-icon
{
  border: 0.1px solid #3767eb; /* Border color */
  color: #007bff;

}
body.dark .category-card , body.dark .card-body,body.dark .chip
{
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.7);
}
body.light .category-card , body.light .card-body , body.light .chip
{
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1); /* Light shadow */

}
body.light .vertical-links-button
{
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
  border: 1px solid #1e1e1e;

}
body.dark .vertical-links-button
{
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.7);
  border: 1px solid #444;

}
body.light .vertical-button , body.dark .vertical-button
{
  background-color: #007bff; 
  color: #fff; 
}
body.light .vertical-button span
{
  color:#000;
}
body.light .hero-button
{
  background-color: #0066ff;
   color: #fff;
}
body.dark .hero-button
{
  background-color: #0066ff;
   color: #fff;
}
/* Light mode */
body.light input::placeholder {
  color: #888; /* Light gray for placeholder text */
}

/* Dark mode */
body.dark input::placeholder {
  color: #ccc; /* Lighter gray for placeholder text in dark mode */
}

body.dark .passIcon {
  color: #ccc; /* Lighter gray for placeholder text in dark mode */
  background-color: transparent !important;
}
body.dark .loginbtn
{
  width: 100px !important;
}
body.light .loginbtn
{
  width: 100px !important;
}
body.light .formDiv
{
  background-color: #ffffff !important; /* White background */
  color: #000000 !important; /* Black text */
}
body.dark .formDiv
{
  background-color: #121212 !important; /* Dark background */
  color: #ffffff !important; /* White text */
}

body.light .chip
{
  
  background-color: #ffffff !important; /* Dark background */
  color: #121212 !important; /* White text */
  border: 0.1px solid #121212;
}
body.dark .chip
{
  background-color: #121212 !important; /* Dark background */
  color: #ffffff !important; /* White text */
  border: 0.1px solid #ffffff;

}
body.light .step-number
{
  background-color: #3498db !important; /* White background */
  color: #ffffff !important;
  border: 0.1px solid #000000;
}
body.dark .step-number
{
  background-color: #121212 !important; /* Dark background */
  color: #ffffff !important; /* White text */
  border: 0.1px solid #ffffff;

}
body.light .step-connector
{
  background-color: #3498db !important;
}
body.dark .step-connector
{
  background-color: #ffffff !important;
}

body.dark .category-card
{
  border: 0.1px solid #444444;

}
body.light .category-card
{
  border: 0.1px solid #444444;

}
body.dark .slick-dots li button::before
{
  color: #ffffff;
}
body.dark .search-input , body.light .search-input
{
  border: 0.1px solid #444444;
}

body.light nav {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Light shadow */
}

/* Dark mode */
body.dark nav {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6); /* Darker, more pronounced shadow */
}
body.dark nav a:hover {
  color: #ddd; /* Change to a bright color like blue for better contrast */
}

/* Light mode hover */
body.light nav a:hover {
  color: #000; /* Black text for light mode */
}
body.dark nav ul a:hover {
  background-color: #333; /* Darker background on hover */
  color: #007bff; /* Bright color for better contrast */
}

body.light nav ul a:hover {
  background-color: #f8f9fa; /* Light background on hover */
  color: #000; /* Black text on hover */
}

body.dark .course-details-container , body.light .course-details-container
{
  border: 0.1px solid #444444;
}

body.light .course-details-container {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Light shadow */
}

/* Dark mode */
body.dark .course-details-container {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6); /* Darker, more pronounced shadow */
}
body.dark .tab , body.light .tab
{
  border: 0.1px solid #444444;
}
body.light .tab {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Light shadow */
}

/* Dark mode */
body.dark .tab {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6); /* Darker, more pronounced shadow */
}

body.light .viewBtn {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6); /* Light shadow */
  background-color: #0056b3; /* Darker blue on hover */
  border-color: #0056b3; /* Matching border */
  color:#fff;

}

/* Dark mode */
body.dark .applyBtn {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6); /* Darker, more pronounced shadow */
  background-color: #1e7e34; /* Slightly lighter blue for contrast */
  border-color: #1e7e34; /* Matching border */
}

body.dark .viewBtn {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6); /* Light shadow */
  background-color: #0056b3; /* Darker blue on hover */
  border-color: #0056b3; /* Matching border */
}

/* Dark mode */
body.light .applyBtn {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6); /* Darker, more pronounced shadow */
  background-color: #28a745; /* Slightly lighter blue for contrast */
  border-color: #28a745; /* Matching border */
  color:#fff;
}
body.light .modalOverlay
{
  background-color: rgba(255, 255, 255, 0.8);
}
body.dark .modalOverlay
{
  background-color: rgba(0, 0, 0, 0.8); /* Dark mode overlay */
}
body.dark .modalOverlay,body.light .modalOverlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998; /* Just below the modal */
}
body.light .objective h1,body.light .perks h1 , body.dark .perks h1  , body.light .course-for h1,body.dark .course-for h1 , body.dark .objective h1, body.light .faq-container h1 , body.light .faq-container h1
{
  color:rgb(227 100 110);
  font-size: 1.875rem;
  line-height: 2.25rem;
}
body.light .objective p
{
  color :#A6ADBA;
}
body.light .course-for h2,body.dark .course-for h2
{
  color:rgb(82 206 255);
  font-size: 1.25rem;
}
body.dark .course-for p,body.light .course-for p
{
  font-size: 0.85rem;
}

body.light .perks p
{
  color :#A6ADBA;
}
body.light .topic-card,body.light .topic-item
{
  background-color: rgb(243 244 246) !important;
color :#444 !important;
height: 100%;
border-radius: 5px;
padding-top: 10px;


}
body.dark .topic-card
{
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8);
}
body.light .topic-card
{
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
body.dark .topic-card ,body.dark .topic-item
{
  background-color: #1e1e1e !important;
  color :#A6ADBA !important;
  height: 100%;
}
body.light .topic-item i,body.dark .topic-item i

{
  color :#A6ADBA !important;

}
body.light .spinner-border
{
  border: var(--bs-spinner-border-width) solid currentcolor !important;
    border-right-color: transparent !important;
    background-color: #fff !important;

}
.home {
    padding: 20px;
    text-align: center;
  }
  
  .header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 60px;
    margin-bottom: 20px;
    color: #333;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .courses-container {
    /* border: 2px solid #007bff; Border color */
    /* border-radius: 8px; */
    padding: 20px;
    background-color: #f8f9fa; /* Light background for contrast */
  }
  
  .course-card {
    transition: transform 0.3s;
  }
  
  .course-card:hover {
    transform: scale(1.05);
  }
  
.rating {
  font-size: 0.85rem;
  display: inline-flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
}
.course-details
{
  display: inline-flex;
  gap: 25px; 
  align-items: start;
  text-align: start;
}

.free-course
{
    background-color: rgb(176 235 255) !important;
    color: rgb(28 80 129) !important;
    width: 3.50rem;
}
.paid-course
{
    background-color: rgb(84 74 42) !important;
    color: rgb(255 197 0) !important;
    width: 6.40rem;
    max-width: 10rem;


}
.course-badge
{
  
  border-radius: 9999px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 10px;
  height: 1.35rem;
  text-transform: uppercase;
  width: auto;
}

.course-topics
{
    background-color: rgb(84 74 42) !important;
    color: rgb(255 197 0) !important;
    width: auto;
    max-width: 8rem;
}
@media (max-width: 768px) {
  .course-details
{
  
  justify-content: center;
    flex-wrap: wrap
}
.course-details-container
{
  padding: 2px !important;
}
}