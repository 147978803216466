
/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #f8f9fa; Light background color */
    padding: 10px 20px; /* Padding for the navbar */
    /* color: #343a40; Dark text color */
    z-index: 1000; /* Ensure it stays above other elements */
}

.fixed-top {
    position: fixed; /* Fixed positioning */
    top: 0; /* Stick to the top */
    width: 100%; /* Full width */
}

.navbar-light .navbar-nav .nav-link {
    /* color: #343a40; Dark text color for links */
    font-size: 18px;
    font-weight: 700;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #007bff; /* Change link color on hover */
}

.navbar-brand {
    /* color: #343a40; Dark color for brand text */
    display: flex; /* Use flex to align logo and text */
    align-items: center; /* Center items vertically */
}

.navbar-brand:hover {
    color: #007bff; /* Change brand color on hover */
}

.navbar-logo {
    max-width: 40px; /* Set a max width for the logo */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Space between logo and brand text */
    border-radius: 50px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .navbar-nav {
        /* background-color: #f8f9fa; Light background for dropdown */
    }
    .navbar-brand
    {
        font-size: 15px !important;
    }
    .navbar-logo {
        max-width: 30px; /* Smaller logo on mobile */
    }
}
.header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px; /* Adjust height as needed */
    background-image: url('../../public/assets/images/header-bg.svg'); /* Correct path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.1;
  }
  
  .cart-icon
  {
    font-size: 24px;
  }
  .rightIcon
  {
    right: 5px;
    position: absolute;
    margin-top: 5px;
    transition: 0.5s;
  }
  .vertical-links-button
  {
    position: fixed;
    bottom: 1%; /* Distance from the bottom */
    width: 50px; /* Button width */
    height: 50px; /* Height can be adjusted */
    display: flex;
    border-radius: 50%;
    flex-direction: column; /* Arrange items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    z-index: 9;
    right:10px;
  }
  .vertical-button {
    position: fixed;
    bottom: 50%; /* Distance from the bottom */
    width: 30px; /* Button width */
    height: 140px; /* Height can be adjusted */
    background-color: #007bff; /* Button background color */
    /* color: #fff; Icon/text color */
    display: flex;
    flex-direction: column; /* Arrange items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    border-radius: 5px; /* Optional for rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    z-index: 9;
  }
  
  .vertical-button:hover {
    background-color: #0056b3; /* Hover color */
    transform: scale(1.1); /* Slightly increase size on hover */
  }
  
  .vertical-button i {
    font-size: 24px; /* Icon size */
    margin-bottom: 5px; /* Space between icon and text */
  }
  
  .vertical-button span {
display: inline-block;
  writing-mode: vertical-rl; /* Makes the text vertical */
  transform: rotate(180deg); /* Rotates the text to read top to bottom */
  text-align: center; /* Centers the text */
  font-size: 16px; /* Adjust font size as needed */
  /* color: #000; Text color */
  }
  