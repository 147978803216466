/* Custom Styling for Cart Page */
.carts
{
    padding: 20px;
}
.carts h2
{
    text-align: center;

}
.cart-items {
    margin-top: 20px;
  }
  
  
  .carts .card .btn
  {
    width: 100%;
  }