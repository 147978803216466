.career-page
{
.chips-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .chip {
    padding: 10px 15px;
    /* background-color: #20262e; */
    /* color: white; */
    border-radius: 50px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    position: relative;
  }
  
  .chip:hover {
    background-color: #0056b3;
  }
  
  .chip:hover::after {
    content: attr(data-title);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    z-index: 1;
    display: block;
  }
  
  .slick-prev, .slick-next {
    z-index: 2;
  }
  
  .slick-slide
    {
    max-width: 250px;
    overflow: hidden;
  }
  .slick-dots {
    margin-top: 20px !important; /* Adds space between the cards and dots */
}
.slick-list
{
    min-height: 80px;
}
@media (max-width: 768px) 
    {
        .slick-list
        {
            min-height: 100px;
        }
    }
    .slick-prev,
.slick-next {
    top:2% !important;
}
}